import React, { ReactNode } from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';

import '../assets/style/utilities/wrapper.css';
import '../assets/style/font.css';

const NotFoundPageStyles = styled.main`
  display: flex;
  justify-content: center;
  margin-top: 10%;
  font-size: var(--fs-big);
  color: var(--text-color);
`;

export default function NotFoundPage(): ReactNode {
  return (
    <>
      <SEO title={`Page introuvable`} />
      <NotFoundPageStyles>Page introuvable</NotFoundPageStyles>
    </>
  );
}
